import React, { Component } from "react";
import { FiCalendar } from "react-icons/fi";

import fenixNight from "../../static/fenix-night.mp4";
import launchEvent from "../../static/launch-event.mp4";

import Translate from "../components/translation/translate";
import Page from "../components/page";
import Newsletter from "../components/newsletter";

class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: null,
            email: "",
        };
    }

    handleEmailChange = e => {
        let value = e.currentTarget.value;
        this.setState({ email: value });
    };

    subscribeToEvents = async () => {
        this.setState({ loading: true });
        await fetch(
            `/campaign-management/add-client?firstName=''&lastName=''&email=${this.state.email}`,
            {
                method: "POST",
            }
        );
        this.setState({ loading: null });
    };

    viewInvitation = index => {
        let invitation = [fenixNight, launchEvent];
        window.location.href = invitation[index];
    };

    render() {
        return (
            <>
                <div
                    className="container-fluid p-4"
                    style={{
                        backgroundImage:
                            "url(https://images.unsplash.com/photo-1467810563316-b5476525c0f9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1049&q=80)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                    <div className="container mt-4 mb-4">
                        <div className="display-2 text-white">
                            <Translate
                                lang={this.props.language}
                                path={["events", "title"]}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="container-fluid p-4"
                    style={{ backgroundColor: "#232d37" }}
                >
                    <div className="container mt-4 mb-4 d-flex flex-md-row flex-column">
                        <div className="col-sm-12 col-md-4 mt-4">
                            <div className="w-100 h-100 bg-white rounded-lg shadow-sm p-4">
                                <FiCalendar
                                    size={24}
                                    className="text-muted mb-4"
                                />
                                <h1>
                                    <Translate
                                        lang={this.props.language}
                                        path={["events", "items", 0, "title"]}
                                    />
                                </h1>
                                <p className="text-muted">
                                    <Translate
                                        lang={this.props.language}
                                        path={[
                                            "events",
                                            "items",
                                            0,
                                            "subtitle",
                                        ]}
                                    />
                                </p>
                                <div className="row flex-row-reverse">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.viewInvitation(0);
                                        }}
                                    >
                                        <Translate
                                            lang={this.props.language}
                                            path={[
                                                "events",
                                                "actionButtonLabel",
                                            ]}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 mt-4">
                            <div className="w-100 h-100 bg-white rounded-lg shadow-sm p-4">
                                <FiCalendar
                                    size={24}
                                    className="text-muted mb-4"
                                />
                                <h1>
                                    <Translate
                                        lang={this.props.language}
                                        path={["events", "items", 1, "title"]}
                                    />
                                </h1>
                                <p className="text-muted">
                                    <Translate
                                        lang={this.props.language}
                                        path={[
                                            "events",
                                            "items",
                                            1,
                                            "subtitle",
                                        ]}
                                    />
                                </p>
                                <div className="row flex-row-reverse">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.viewInvitation(1);
                                        }}
                                    >
                                        <Translate
                                            lang={this.props.language}
                                            path={[
                                                "events",
                                                "alternateButtonLabel",
                                            ]}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter
                    lang={this.props.language}
                    titlePath={["events", "eventSubscription", "title"]}
                    subtitlePath={["events", "eventSubscription", "subtitle"]}
                    style={{ backgroundColor: "#384451" }}
                />
            </>
        );
    }
}

export default () => (
    <Page>
        <Events />
    </Page>
);
